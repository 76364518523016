import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer.js'; 
import { logger } from './logger.js'; 

const store = configureStore({
  reducer: reducer, 
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger),
 
});

export default store;
