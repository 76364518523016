import logo from './logo.svg';
import './App.css';
import IndexLanding from './indexLanding';
import { Route } from 'react-router-dom';
import Registration from './Components/indexRegistartion.jsx/Registration';
import Router from './Routes/IndexRout';

function App() {
  return (
    <>
<Router/>
</>
  );
}

export default App;
