import React, { useEffect } from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Container, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import '../../../Style/Cabinet.scss'
import PersonIcon from '@mui/icons-material/Person';
import PaymentsIcon from '@mui/icons-material/Payments';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import TelegramIcon from '@mui/icons-material/Telegram';
function Cabinet () {

    const data = useSelector((state) => state.data)

    useEffect(() =>{
        console.log(data)
    },[data])

    const styleDivider = {
        width: '90%',
        margin: "0 auto"
    }
    return(
        <>
        {data && 
        <div className="wrapper">
            <div className="myAccountWrapper">
            <div className="myAccount">
            <div className="myAcountImageWrapper">
            <div className="myAccountImage" />
            level <br/>
            </div>
            <div className="myAccountTextWrapper">
            <div className="myAccontName">
                Lorem ipsum
            </div>
            <div className="myAccountText">
            <PersonIcon/>
            Lorem ipsum
            </div>
            </div>
            </div>
            <Divider style={styleDivider}/>
            <div className="myAccountPortfolioWrapper">
                <div className="myAccountPortfolio">
                    <div className="imgPortfolioWrapper">
                    <PaymentsIcon/>
                    </div>
                    <div className="portfolioDescription">
                    <b>$</b>
                        Личный активный портфель
                    </div>
                </div>
                <div className="myAccountIncome">
                <div className="imgIncomeWrapper">
                <PaymentsIcon/>
                </div>
                <div className="incomeDescription">
                <b>$</b>

                    Общий полученный доход
                    </div>
                </div>
            </div>
            <Divider style={styleDivider}/>
            </div>
            <div className="consultantLable">
                Ваш консультант
            </div>
            <div className="consultantWrapper">
            <div className="consultant">
            <div className="consultantImageWrapper">
            <div className="consultantImage" />
            level <br/>
            </div>
            <div className="consultantTextWrapper">
            <div className="consultantName">
                Lorem ipsum
            </div>
            <div className="consultantText">
            <PersonIcon/>
            Lorem ipsum
            </div>
            </div>
            </div>
            <div className="socialNetworkWrapper">
                <div className="socialNetwork">
                <div className="socialNetworkImg">
                <EmailIcon/>
                Lorem ipsum
                </div>
                <div className="socialNetworkImg">
                <TelegramIcon/>
                Lorem ipsum
                </div>
                <div className="socialNetworkImg">
                <PhoneIcon/>
                Lorem ipsum
                </div>
                </div>
            </div>
            </div>
        </div>
        }
        </>
    )
}

export default Cabinet