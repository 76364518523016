import React from "react";
import RegistrationHeader from "./RegistationHeader";
import RegistrationBody from "./RegistartionBody";
import RegistrationFooter from "./RegistartionFooter";


function Registration () {
    return(
    <>
    <RegistrationHeader/>
    <RegistrationBody/>
    {/* <RegistrationFooter/> */}
    </>
    )
}

export default Registration