import logo from './logo.svg';
import './App.css';
import LandingHeader from './landing/landingHeader';
import LandingFooter from './landing/landingFooter';
import LandingBody from './landing/landingBody';

function IndexLanding() {
  return (
    <>
<LandingHeader/>
<LandingBody/>
<LandingFooter/>
</>
  );
}

export default IndexLanding;
