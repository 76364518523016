import React from "react";
import Navigation from "./Navigation/Navigation";
import Header from "./Header/Header";
import { Box } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import Cabinet from "./Cabinet/Cabinet";

function MainPage () {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    const style = {
        width: '100%',
        display: 'flex'
    }

    return (
        <Box style={style}>
        <Navigation isSmallScreen={isSmallScreen} />
        <Header theme={theme} isSmallScreen={isSmallScreen} />
        <Cabinet/>
        </Box>
    )
}

export default MainPage