import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: true
}

const openCabinetSlice = createSlice({
    name: 'openCabinet',
    initialState,
    reducers:{
        setData: (state, action) =>{
            state.data = action.payload
        }
    }
})

export const {setData} = openCabinetSlice.actions
export default openCabinetSlice.reducer