import React, { useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import NavigationAdaptive from "../Navigation/NavigationAdaptive";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch } from "react-redux";
import { setData } from "../../../store/reducer";
import '../../../Style/MainHeader.scss'
import TelegramIcon from '@mui/icons-material/Telegram';
import FlagIcon from '@mui/icons-material/Flag';
import {Divider } from "@mui/material";

function Header ({isSmallScreen}) {
    const [cabinetOpen, setCabinetOpen] = useState(true)
    const theme = useTheme()
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const dispatch = useDispatch()

    const handlerOpenDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen)
    }

    const handleMenuClick = () => {
        setIsDrawerOpen((prevIsOpen) => !prevIsOpen);
    }

    const handleOpenCabinet = () =>{
        setCabinetOpen(!cabinetOpen)
        dispatch(setData(cabinetOpen))

    }

    const style = {
        background: 'white',
        display: 'flex'
    }

    useEffect(() =>{

    },[cabinetOpen])

    return(
        <div className="landingHeaderWrapper" style={{width: "100%"}}>
    <NavigationAdaptive isOpen={isDrawerOpen} isClose={isSmallScreen} onClose={handleMenuClick}/>
            <div className="landingHeader">
            <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={style}> 
        <Toolbar style={{display:'flex', justifyContent:'space-around'}}>
        {isSmallScreen && (
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleMenuClick}
            >
                <MenuIcon />
            </IconButton>
        )}
          <Typography style={{color: "black", maxWidth: '100px'}} variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LOGO
          </Typography>
            <div className="boxCountOfUser">

            </div>
            <button className="buttonAddWallet">
                Подключить кошелек
            </button>
            <button className="buttonPartnersOffice">
                В Кабинет Партнёра 
            </button>
            <button className="buttonSocialNetworkWrapper">
                <div className="iconSocialNetwork">
                <TelegramIcon/>
                </div>
                <span>Lorem ipsum</span> 
            </button>
            <button className="buttonSettingsWrapper">
                <div className="flagWwrapper">
                <FlagIcon/>
                </div>
               <div className="languageValueWrapper">
                <span>Русский, USD</span>
               </div>
            </button>
        <ArrowForwardIcon style={{ color: "black"}} onClick={handleOpenCabinet}/>
        </Toolbar>
      </AppBar>
    </Box>
            </div>
        </div>
    )
}

export default Header