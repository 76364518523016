import { Route, Routes } from "react-router-dom";
import React from 'react';
import Registration from "../Components/indexRegistartion.jsx/Registration";
import IndexLanding from "../indexLanding";
import MainPage from "../Components/MainPage/MainPage";


function Router() {
    return (
      <>  
  <Routes>
        <Route path="/" element={<IndexLanding/>}/>
        <Route path="/registartion" element={<Registration/>} />
        <Route path="/mainpage" element={<MainPage/>}/>
  </Routes>
  </>
    );
  }
  
  export default Router;