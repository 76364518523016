import React from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ClearIcon from '@mui/icons-material/Clear';

function Navigation ({isSmallScreen}) {

    return(
        <>
       {!isSmallScreen &&  <Drawer
        open={!isSmallScreen}
        sx={{
        //   width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            // width: drawerWidth,
            boxSizing: 'border-box',
            position: 'static',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row'
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          {['Lorem', 'Голосование', 'Торговля долями', 'Кабинет клиента', 'Профиль', 'Счета', 'Выйти'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                {/* <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon> */}
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      }
      </>
    )


}

export default Navigation