import React from "react";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

function LandingBody () {
    const style = {
        width: '100%',
        height: '80vh',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
    const styleButton = {
        background: 'black'
    }
    return(
        <div style={style}>
            <Link to="/registartion" style={styleButton}>
                <Button style={styleButton} variant="contained">Зарегистрироваться</Button>
            </Link>
        </div>
    )
}

export default LandingBody