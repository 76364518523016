import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { border, display, margin } from "@mui/system";
import TextField from '@mui/material/TextField';
import { MuiFileInput } from 'mui-file-input'
import Button from '@mui/material/Button';
import '../../Style/RegistrationBody.scss'
import { useNavigate } from "react-router-dom";

function RegistrationBody () {
  const [value, setValue] = React.useState('1');
  const [file, setFile] = React.useState(null)
  const [valueInputLogin, setValueInputLogin] = useState('')
  const [valueInputPassword, setValueInputPassword] = useState('')
  const navigate = useNavigate()  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleChangeForm = (newFile) => {
    setFile(newFile)
  }

  const buttonAuth = () => {
    if(valueInputLogin && valueInputPassword){
      navigate('/mainpage')
    }
  }

  const handleChangeLogin = (event) => {
    setValueInputLogin(event.target.value)
  }

  const handleChangePassword = (event) => {
    setValueInputPassword(event.target.value)
  }

  
  const styleTabs = {
    width: '90%',
    height: '20%',
    maxWidth:'400px',
    margin: '0 auto',
    border: '1px solid',
    marginTop: '15%'
  }
  const styleButtonTubs = {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
}
const styleButton = {
    background: 'black',
    margin: '10px auto',
    width: '70%'
}
  return (
    <Box style={styleTabs}>
      <TabContext value={value}  >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example"  centered={true}>
            <Tab label="Авторизация" value="1" />
            <Tab label="Регистрация" value="2" />
          </TabList>
        </Box>
        
        <TabPanel value="1" style={{marginBottom: '10px ', display:'flex', justifyContent:"center", flexDirection:'column'}}>
            <h1 style={{marginBottom: '10px ', display:'flex', justifyContent:"center"}}>Авторизация</h1>
            <Box sx={{height: '120px', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}} value='1'>
        <TextField value={valueInputLogin} onChange={handleChangeLogin} sx={{width: '70%'}} id="outlined-basic" label="Outlined" variant="outlined" />
        <TextField type="password" value={valueInputPassword} onChange={handleChangePassword} sx={{width: '70%'}} id="outlined-basic" label="Outlined" variant="outlined" />
        </Box>
        <Button style={styleButton} onClick={buttonAuth} variant="contained">Войти</Button>

        </TabPanel>
        <TabPanel value="2" style={{marginBottom: '10px ', display:'flex', justifyContent:"center", flexDirection:'column'}}>   
        <h1 style={{marginBottom: '10px ', display:'flex', justifyContent:"center"}}>Вставьте фото</h1>
          <Box sx={{height: '120px', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}} value='1'>
          <MuiFileInput value={file} onChange={handleChangeForm} />
        </Box>
        <Button style={styleButton} variant="contained">Зарегестрироваться</Button>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default RegistrationBody